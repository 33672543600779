import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/Layout";
import Header from "../../components/Header";
import BlogList from "../../components/BlogList";

export default function Index({
  data: {
    contentfulJobsPage,
    contentfulNavigationBar,
    contentfulFooter,
  },
}) {
  const data = contentfulJobsPage;

  let empty = false;

  if (
    data.listedContentPages.length === 1 &&
    data.listedContentPages[0].heading === "Placeholder"
  ) {
    empty = true;
  } else {
      empty = false;
  }

  return (
    <Layout
      footerElements={contentfulFooter}
    >
      <Header
        heading={data.heading}
        subheading={data.subheading}
        buttonList={data.buttonList}
        vanish={true}
        image={data.headerImage}
        navBar={contentfulNavigationBar.navigationBarList}
      />
      {empty ? <></> : <BlogList contentPages={data.listedContentPages} />}
    </Layout>
  );
}

export const query = graphql`
  query {
    contentfulJobsPage(node_locale: { eq: "de" }) {
      ...HeaderWithoutButtonsJobs
      listedContentPages {
        headerImage {
          gatsbyImageData
        }
        slug
        heading
        date
        durationInMinutes
        summary {
          raw
        }
      }
    }
    contentfulNavigationBar(node_locale: { eq: "de" }) {
      navigationBarList {
        linksToSlug
        title
      }
    }
    contentfulFooter(node_locale: { eq: "de" }) {
      footerElements {
        linksToSlug
        title
      }
    }
  }
`;
